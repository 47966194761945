import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import BlogCard from "../components/BlogCard";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/page/author_detail.scss";

const AuthorDetail = ({ data, location }) => {
  const image = getImage(data?.prismicAuthor?.data?.avatar);
  return (
    <Layout location={location}>
      <section>
        <div className="mt-20 moving-bg">
          <div className="max-w-4xl py-12 mx-auto md:pt-16 md:pb-8">
            <div className="border-black border-dashed border-1"></div>
            <section className="flex flex-col items-center gap-4 p-9">
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={
                  data?.prismicAuthor?.data?.full_name?.text ||
                  "profile picture"
                }
                className="border-4 border-purple-500 rounded-full h-36 w-36"
              />
              <div className="text-center">
                <h1 className="h3">
                  {data?.prismicAuthor?.data?.full_name?.text}
                </h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.prismicAuthor?.data?.bio?.html,
                  }}
                  className="encode-blogs-content"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <div className="mb-12 mt-28 md:mb-20 full-bleed">
            {/* Section header */}
          </div>
          <div className="pb-12">
            <section className="mt-8">
              <h3 className="h3">
                All blogs by {data?.prismicAuthor?.data?.full_name?.text}
              </h3>

              <div className="grid grid-cols-1 mt-10 gap-7 sm:grid-cols-2 lg:grid-cols-3">
                {data?.allPrismicBlog &&
                  data?.allPrismicBlog?.nodes?.map((blog, index) => {
                    return (
                      <BlogCard
                        blog={{ node: { ...blog } }}
                        location={location}
                        key={`blog_card_${index}`}
                      />
                    );
                  })}
              </div>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`query AuthoDetailWithBlogs($uid: String!) {
  allPrismicBlog(filter: {data: {author: {uid: {eq: $uid}}}}) {
    nodes {
      uid
      tags
      data {
        author {
          document {
            ... on PrismicAuthor {
              uid
              data {
                full_name {
                  text
                }
              }
            }
          }
        }
        image {
          url
          gatsbyImageData(width: 400, placeholder: BLURRED)
        }
        sub_image {
          url
          gatsbyImageData(width: 400, placeholder: BLURRED)
        }
        read_time
        title {
          text
        }
      }
    }
    distinct(field: {tags: SELECT})
  }
  prismicAuthor(uid: {eq: $uid}) {
    uid
    data {
      avatar {
        url
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
      full_name {
        text
      }
      bio {
        html
        text
      }
    }
  }
}`;

export default AuthorDetail;

export const Head = ({ data }) => (
  <SEO
    title={`${data?.prismicAuthor?.data?.full_name?.text} | Encord`}
    description={data?.prismicAuthor?.data?.bio?.text.slice(0, 144)}
  />
);
